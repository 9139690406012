import './index.css'

function Amer() {
    return (
        <>
            <h1>  My Technology House       </h1>
            <h2>    A Portfolio Of My Authoried Work</h2>

            <img
                src='./amer.png'
                alt='americana'
            />
              <div className="one">
                 <a href="//www.MyOneUniverse.com">My One Universe Network Link</a>
              </div>

            <div className="one">

                <p>      House Of Webs.com</p><p>
                   Technology And Times.com</p><p>
                    The Branded Series.com</p><p>
                Projects Websites</p><p>
                    more</p>

           

            </div>
          





   


                <div className="one">
                    <a>2022(c), MyOneUniverse.com, Optical Automation, LLC</a>
                </div>


       

        </>

    )
}

export default Amer;